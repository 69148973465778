import { BaseService } from '@/core/base-service';
import axios from 'axios';
import generateQueryOptions from '@/shared/sort/sorts';

export default class ProgramSettingService extends BaseService<any> {
  public constructor() {
    super('services/serviceauttech/api/setting');
  }

  public createOrUpdate(settings: any): Promise<any> {
    return axios.post(this.endpointName, settings);
  }

  public getSetting(settingsKey: string, userId: string): Promise<any> {
    const queryParam = {
      filterParam: '?userId=' + (userId ? userId : ''),
    };
    return axios.get(this.endpointName + `/${settingsKey}` + generateQueryOptions(queryParam));
  }
}
