/* eslint-disable vue/no-reserved-component-names */
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import PrimeVue from 'primevue/config';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Tooltip from 'primevue/tooltip';

export function initPrimeVue(app: any) {
  app.use(PrimeVue);
  app.component('Accordion', Accordion);
  app.component('AccordionTab', AccordionTab);
  app.component('Avatar', Avatar);
  app.component('Badge', Badge);
  app.component('Button', Button);
  app.component('Calendar', Calendar);
  app.component('Card', Card);
  app.component('Chart', Chart);
  app.component('Checkbox', Checkbox);
  app.component('ColorPicker', ColorPicker);
  app.component('Column', Column);
  app.component('ContextMenu', ContextMenu);
  app.component('DataTable', DataTable);
  app.component('Dialog', Dialog);
  app.component('Divider', Divider);
  app.component('Dropdown', Dropdown);
  app.component('Editor', Editor);
  app.component('Fieldset', Fieldset);
  app.component('FileUpload', FileUpload);
  app.component('Image', Image);
  app.component('InlineMessage', InlineMessage);
  app.component('InputMask', InputMask);
  app.component('InputNumber', InputNumber);
  app.component('InputSwitch', InputSwitch);
  app.component('InputText', InputText);
  app.component('Menu', Menu);
  app.component('Menubar', Menubar);
  app.component('MultiSelect', MultiSelect);
  app.component('OverlayPanel', OverlayPanel);
  app.component('Panel', Panel);
  app.component('Password', Password);
  app.component('PickList', PickList);
  app.component('ProgressBar', ProgressBar);
  app.component('ProgressSpinner', ProgressSpinner);
  app.component('RadioButton', RadioButton);
  app.component('SelectButton', SelectButton);
  app.component('Slider', Slider);
  app.component('Splitter', Splitter);
  app.component('SplitterPanel', SplitterPanel);
  app.component('Steps', Steps);
  app.component('TabPanel', TabPanel);
  app.component('TabView', TabView);
  app.component('Tag', Tag);
  app.component('Textarea', Textarea);
  app.component('Toast', Toast);
  app.component('ToggleButton', ToggleButton);
  app.component('Tree', Tree);
  app.component('TriStateCheckbox', TriStateCheckbox);
  app.directive('badge', BadgeDirective);
  app.directive('tooltip', Tooltip);
}
