import * as VueRouter from 'vue-router';
import { Authority } from '@/shared/model/authority';
import { createRouter } from 'vue-router';

import admin from '@/router/admin';
import pages from '@/router/pages';

const HomeOAuth = () => import('@/core/home/home-oauth.vue');
const Error = () => import('@/core/error/error.vue');

// prettier-ignore
export function initRouter() {
  return createRouter({
    history: VueRouter.createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'Home',
        component: HomeOAuth,
        meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER] },
      },
      {
        path: '/forbidden',
        name: 'Forbidden',
        component: Error,
      },
      {
        path: '/not-found',
        name: 'NotFound',
        component: Error,
      },
      ...admin,
      ...pages,
    ]
  });
}
