import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class QuestionnaireInterpretationService extends BaseService<any> {
  public constructor() {
    super('services/serviceauttech/api/questionnaire-interpretation');
  }

  public getQuestionsByQuestionnaireId(id: any): Promise<any> {
    return axios.get(this.endpointName + '/get-questions-by-questionnaire?questionnaireId=' + id);
  }

  public getInterpretationByQuestionnaireQuestionId(questionnaireId: any, questionId: any): Promise<any> {
    return axios.get(
      this.endpointName + '/get-questions-by-questionnaire-and-question?questionnaireId=' + questionnaireId + '&questionId=' + questionId,
    );
  }
}
