import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class QuestionService extends BaseService<any> {
  public constructor() {
    super('services/serviceauttech/api/call-for-tender');
  }

  public getCallForTenderCombobox(): Promise<any> {
    return axios.get(this.endpointName + '/combobox');
  }
}
