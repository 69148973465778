import { Authority } from '@/shared/model/authority';

const CallForTenderComponent = () => import('@/project/call-for-tender/call-for-tender.vue');
const DashboardComponent = () => import('@/project/dashboard/dashboard.vue');
const DashboardDetailComponent = () => import('@/project/dashboard/dashboard-detail.vue');
const ProgramSettingsComponent = () => import('@/project/program-settings/program-settings.vue');
const ProjectDetailComponent = () => import('@/project/project/project-detail.vue');
const ProjectManagementComponent = () => import('@/project/project/project-management.vue');
const QuestionDetailComponent = () => import('@/project/question/question-detail.vue');
const QuestionnaireDetailComponent = () => import('@/project/questionnare/questionnaire-detail.vue');
const QuestionsManagementComponent = () => import('@/project/question/question-management.vue');

export default [
  {
    path: '/question',
    name: 'Questions',
    component: QuestionsManagementComponent,
    meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN] },
  },
  {
    path: '/question/:id',
    name: 'QuestionDetail',
    component: QuestionDetailComponent,
    meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN] },
  },
  {
    path: '/project',
    name: 'Project',
    component: ProjectManagementComponent,
    meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN] },
  },
  {
    path: '/project/:id',
    name: 'ProjectDetail',
    component: ProjectDetailComponent,
    meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN] },
  },
  {
    path: '/call-for-tender',
    name: 'CallForTender',
    component: CallForTenderComponent,
    meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN] },
  },
  {
    path: '/questionnaire/:id',
    name: 'QuestionnaireDetail',
    component: QuestionnaireDetailComponent,
    meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN] },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardComponent,
    meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN] },
  },
  {
    path: '/dashboard/:id',
    name: 'DashboardDetail',
    component: DashboardDetailComponent,
    meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN] },
  },
  {
    path: '/settings/program',
    name: 'Program settings',
    component: ProgramSettingsComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
];
