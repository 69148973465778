import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class SolutionService extends BaseService<any> {
  public constructor() {
    super('services/serviceauttech/api/solution');
  }

  public getOptions() {
    return axios.get(this.endpointName + '/combobox');
  }
}
